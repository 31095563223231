<template>
  <div>
    <a-card title="销售合同详情">
      <a-button slot="extra" v-print="'#printContent'" ghost icon="printer" style="margin-right: 8px" type="primary">
        打印
      </a-button>
      <a-button slot="extra" ghost icon="left" type="primary" @click="$router.go(-1)">返回</a-button>

      <section id="printContent">
        <a-spin :spinning="queryLoading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="销售合同单号">
              {{ dataItem.number }}
            </a-descriptions-item>
            <a-descriptions-item label="客户">
              {{ dataItem.client_name }}
            </a-descriptions-item>
            <a-descriptions-item label="状态">
              {{ dataItem.status_display }}
            </a-descriptions-item>
            <a-descriptions-item label="销售数量">
              {{ dataItem.total_quantity }}
            </a-descriptions-item>
            <a-descriptions-item label="销售金额">
              {{ dataItem.total_amount }}
            </a-descriptions-item>
            <a-descriptions-item label="制单人">
              {{ dataItem.handler_name }}
            </a-descriptions-item>
            <a-descriptions-item label="制单时间">
              {{ dataItem.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="合同单号">
              {{ dataItem.contract_number }}
            </a-descriptions-item>
            <a-descriptions-item label="交货日期">
              {{ dataItem.delivery_date }}
            </a-descriptions-item>
            <a-descriptions-item label="联系人">
              {{ dataItem.contact_person }}
            </a-descriptions-item>
            <a-descriptions-item label="联系电话">
              {{ dataItem.contact_phone }}
            </a-descriptions-item>
            <a-descriptions-item label="地址">
              {{ dataItem.address }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ dataItem.remark }}
            </a-descriptions-item>
            <a-descriptions-item label="附件">
              <div v-for="fileItem in dataItem.sales_file_items" :key="fileItem.id">
                <a :href="fileItem.file" target="_blank" style="margin-right: 16px">{{ fileItem.name }}</a>
              </div>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider orientation="left" style="margin-top: 30px">物料信息</a-divider>
          <a-table :columns="columns" :dataSource="goodsItems" :pagination="false" rowKey="id" size="small" />
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { salesOrderDetail } from "@/api/sale";

export default {
  data() {
    return {
      queryLoading: false,
      dataItem: {},
      goodsItems: [],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料条码",
          dataIndex: "goods_barcode",
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
        },
        {
          title: "物料单位",
          dataIndex: "goods_unit",
        },
        {
          title: "销售数量",
          dataIndex: "sales_quantity",
        },
        {
          title: "销售单价",
          dataIndex: "sales_price",
        },
        {
          title: "销售金额",
          dataIndex: "sales_amount",
        },
      ],
    };
  },
  methods: {
    initData() {
      this.queryLoading = true;
      salesOrderDetail({ id: this.$route.query.id })
        .then((data) => {
          this.dataItem = data;
          this.goodsItems = data.sales_goods_items;
        })
        .finally(() => {
          this.queryLoading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
